<template>
  <!--	<meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover">-->
  <div>
    <div v-if="androidFlg == false">
      <van-nav-bar v-if="messageTo" title="付款审批" left-arrow @click-left="_onBackTapInfo" fixed z-index="99" placeholder />
      <rxNavBar v-else  :title="title"></rxNavBar>
    </div>
    <div v-else>
      <!-- <rxNavBar v-if="messageTo" :returnPage="routerBack" :title="title" androidOrIOSFlag="true"></rxNavBar> -->
      <rxNavBar :title="title" androidOrIOSFlag="true"></rxNavBar>
    </div>
    <!-- 顶部固定区域-->
    <div class="head">

	  <div class="search">
	      <div class="search-icon" ></div>
	      <input type="text" placeholder="房源地址/收付款人/金额/账单包id" v-model="searchName" @keyup.enter="goSearch">
	  </div>
    </div>
    <!-- 显示一共几笔账单 -->
    <div class="totalBill">
      共有{{useList.length}}笔待支付账单；共计{{totalSum}}元
    </div>
      <!--上拉刷新-->
   <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" >
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="noContent ? '' : '已经到底了'"
            @load="this.onLoad"
              >
            <!-- 账单列表 -->
            <div class="billList">
              <van-checkbox-group v-model="result" ref="checkboxGroup">
                <div class="billInfo" v-for=" (item,i) in useList " :key="i">
                  <div>
                  <van-checkbox class="checkBox" ref="checks" :name="item.id" checked-color="#ffa65c" shape="square" icon-size="15px" @click="billAction(item.totalAmount)">
                   </van-checkbox>
                  </div>
                   <div @click="checkDetail(item)">

                      <!-- 费用详情 -->
                      <div class="detail">
                        <span style="font-size: 16px;">  <van-icon name="warning" color="#ffc31f" style="margin-top: 4px;margin-right: 5px" v-if="item.changeFlag == 1"/>{{item.title}}</span>
                      </div>
                      <!-- 应缴时间 -->
                     <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px">
                       <div class="time">【{{item.id}}】共计{{item.count}}笔{{item.feeTime}}</div>

                       <div class="money">{{item.totalAmount}}元</div>
                     </div>

                  </div>
                </div>

              </van-checkbox-group>
            </div>
        </van-list>
      </van-pull-refresh>
    <div class="payment">
      <van-checkbox
        class="btomcheck btomCheckNew"
        type="primary"
        @click="ifcheck"
        v-model="checked"
        checked-color="#ffa65c"
        shape="square"
        icon-size="15px"
        >全选</van-checkbox
      >
      <!--			<div class="totalMoney">-->
      <div class="totalMoneyBox">
        <div class="totalMoneyTitle">总计&nbsp;</div>
        <div class="totalMoneyValue">¥{{ sum }}</div>
      </div>
      <!--			</div>-->
      <div class="bottomNavigationB bottomNavigationBNew" @click="payment">
        审批
      </div>
    </div>

    <!-- 审批图层 -->
    <van-popup v-model:show="show" style="background-color: #FAFAFA"  position="bottom" :style="{ height: '60%' }">
      <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;"
           src="../../assets/images/colon.png">
      <div style="margin-top: 18px;margin-bottom: 20px;font-size: 15px;font-weight: bold">支付审核</div>
	   <div class="normalBlock" >
	       <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
	       <span class="blockTitle">审核结果</span>
	       <div class="rightText">
	           <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
	           <span class="content-divide" > | </span>
	            <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
	       </div>
	   </div>
	   <div class="displayBlock">
        <span v-if="isReject == true && isPass == false" class="maker" :class="approvalOpinion.length > 0 ? 'orangeColor' : ''">• </span>
	       <div><span :class="isReject == true && isPass == false ? 'suggestTitle' : 'suggestTitleOther'">审批意见</span></div>
	       <div><textarea  class="textArea" v-model="approvalOpinion"></textarea></div>
	   </div>
	   <van-button  class="saveButton_Disable" :class="{'saveButton_Enable': isPass || (isReject && approvalOpinion.length > 0 )}"
	                :disabled=" !(isPass || (isReject && approvalOpinion.length > 0 ))" @click="save" >保存
	   </van-button>

	</van-popup>
	<!-- 审批图层结束 -->
    <!--		<div class="iphoneXBottom"></div>-->
    <!-- 没有数据时显示 -->
<!--    <div class="empty" v-if="useList== []">-->
<!--      <img src="../../assets/images/empty.png" alt="">-->
<!--      <div class="empryText">啊哦，这里好像什么都没有</div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {
  NavBar,
  Picker,
  List,
  PullRefresh,
  Toast,
  Popup,
  Checkbox,
  Search,
  CheckboxGroup,
  Button,
  Icon
} from "vant";
Vue.use(Picker);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
import {
    approvalMergeBill,
    queryBaseData, queryPayBillList, reviewOfApplyForHouseList,
    userBillList
} from "../../getData/getData";
import {
    checkAndroid, checkIOS, openInWebview,
    getUserId,
    globaluserId,
    responseUtil, getStaffId
} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import Vue from 'vue';
import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile';

//************** 跳转原生立即支付页面 start ******************
// function goBill(billIdList,totalMoney,billType) {
//   window.himi.gotoPayBill(billIdList,totalMoney,billType);
// }
//************** 跳转原生立即支付页面 end   ******************

export default {
  name: "paymentApproval",
  data() {
    return {
      messageTo: false,
      routerBack: '',
      routerParams: {},
      androidFlg: '',
      title:'',
        totalSum:0,
        noContent: false,
        refreshing:false,
      result: [],
      show: false,
        count:0,  // 总条数
      showPicker: false,
      condition: true, //用来控制两种状态的切换条件
      isOccupationShow: false,
      loading: false,
      finished: false,
      certificateType: '',
      isOccupationShow: false,
      defaultIndex: '',
      swi: true,
      isLoading: false,
      totalPage: '',
      checked: false,
      currentList: [],
      futureList: [],
      useList: [],
      billOwnerType: '1', //账单类型(0 业主 1 租客 2 房屋)
      display: true,
      sum: 0,
      billId: [],
      billType: [],  //账单类型
	  isPass:false,
        isPassValue:'',
	  isReject:false,
	  approvalOpinion:'',//审批意见
	  inputValue:'',
      currentPage:1,//当前页
      numberPage:50,//每页条数
      houseType:1,//账单类型( 1业主类 2解除类 3 租后类 4 装修类 5 渠道类)
      searchName:"",//查询内容,可以搜索：姓名，地址，金额
      isShow: false,
      isInvoiceList:[],  //是否有发票数组
      isInvoiceRequiredList:[],  //是否需要发票数组
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
	[Search.name]:Search,
	[Button.name]: Button,
    dialMobile,
    rxNavBar

  },
  created() {
    // this.initDicData();
  },
  //初始化
  mounted() {
    this.checkPhoneorMobel()
    if(this.$route.query.messageFlag && this.$route.query.messageFlag == 1) {
				this.messageTo = true
				this.routerBack = this.$route.query.linkPage
				this.routerParams = { messageFlag: 1,linkPage: '' }
			}
    if(this.$route.query.type != undefined){
      if(this.$route.query.type == 1){
        this.title = "业主类支付";
        this.houseType = 1;
      }else if(this.$route.query.type == 2){
        this.title = "渠道费支付";
        this.houseType = 5;
      }else if(this.$route.query.type == 3){
        this.title = "解除类支付";
        this.houseType = 2;
      }else if(this.$route.query.type == 4){
        this.title = "装修款支付";
        this.houseType = 4;
      }else if(this.$route.query.type == 5){
        this.title = "租后类支付";
        this.houseType = 3;
      }else if(this.$route.query.type == 15){
        this.title = "中后台支付";
        this.houseType = 15;
      }
    }else{
       this.houseType = '';
       this.title = "业主类支付";
    }
   

  },
  methods: {
    _onBackTapInfo() {
				window.webkit.messageHandlers.openWebPage.postMessage({url:this.routerBack +  "?messageFlag=1"});
			},
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    //用户账单列表接口
      onLoad() {
      let that = this
      let initData = {};
      // initData.user_id = globaluserId(); //用户id
      // initData.billOwnerType = that.billOwnerType;
      initData.user_id = getStaffId();
      initData.currentPage = that.currentPage;
      initData.numberPage = that.numberPage;
      initData.houseType = that.houseType;
      initData.searchName = that.searchName;
      queryPayBillList(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.data;
            if(list == undefined){
              list = []
            }
            if (1 == that.currentPage && (!list || 0 == list.length)) {
              that.noContent = true
            } else {
              that.noContent = false
            }
            that.count = response.data.data.pageCount
            that.totalSum = response.data.data.sum
            for (let i = 0; i < list.length; i++) {
                that.useList.push(list[i])
            }
            // 加载状态结束
            that.loading = false;
            if(that.refreshing){
                that.refreshing = false
            }
            // 数据全部加载完成
            if (that.useList.length >= that.count) {
                that.finished = true;
            }


            that.currentPage++;
        })
      })
    },
    showAllOwner() {
      let that = this;
      if (that.futureList.length == that.currentList.length) {
        Toast('没有更多账单了')
        that.display = false
      } else {
        that.useList = that.futureList;
        that.display = false
      }
    },
    billAction(e) {
      let checkList = this.$refs.checks
      var num = 0
      this.sum = 0;
      this.billId = [];
      this.isInvoiceList=[]
      this.isInvoiceRequiredList=[]
      this.billType = [];
      for (let i = 0; i < checkList.length; i++) {
        let checkedBill = checkList[i].checked;
        if (checkedBill === true) {
          let checkedMoney = this.useList[i].totalAmount;
          let billId = this.useList[i].id + '';
          let isInvoice = this.useList[i].isInvoice
          let isInvoiceRequired = this.useList[i].isInvoiceRequired
          num += Number(checkedMoney);
          this.sum = num.toFixed(2);
          this.billId.push(billId);
          this.isInvoiceList.push(isInvoice)
          this.isInvoiceRequiredList.push(isInvoiceRequired)
        }
      }
      //console.log(this.billId)
    },
    checkDetail(e){
        this.$router.push({
          name: 'paymentDetail',
          query:{
              addTime:e.addTime,
              contractCode:e.contractCode,
              count:e.count,
              title:e.title,
              totalAmount:e.totalAmount,
              id:e.id,
            changeFlag:e.changeFlag
          }
        });
    },
    ifcheck() {
      let that = this
      if (that.checked == true) {
        that.checkAll();
      }
      if (that.checked == false) {
        that.toggleAll();
      }
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
      this.sum = 0;
      var num = 0
      this.billId = [];
      this.isInvoiceList=[]
      this.isInvoiceRequiredList=[]
      this.billType = [];
      for (let i = 0; i < this.useList.length; i++) {
        let checkedMoney = this.useList[i].totalAmount;
        let billId = this.useList[i].id + '';
        let isInvoice = this.useList[i].isInvoice
        let isInvoiceRequired = this.useList[i].isInvoiceRequired
        num += Number(checkedMoney);
        this.sum = num.toFixed(2);
        this.billId.push(billId);
        this.isInvoiceList.push(isInvoice)
          this.isInvoiceRequiredList.push(isInvoiceRequired)
      }
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
      this.sum = 0;
      this.billId = [];
      this.isInvoiceList=[]
      this.isInvoiceRequired=[]
    },
	choosePass(){
	    this.isPass = true;
	    this.isReject = false;
	    this.isPassValue = 0;
	},
	chooseReject(){
	    this.isReject = true;
	    this.isPass = false;
        this.isPassValue = 1;
	},

    showPopup() {
      this.show = true;
    },

    //返回上一页
    leftReturn() {
      this.$router.go(-1)
    },
    default () { //默认值
      this.useList = [];
      this.display = true;
      this.result = [];
      this.checked = false;
      this.sum = 0;
    },
	//搜索框查询
	goSearch(){
      this.sum = 0;
      this.$refs.checkboxGroup.toggleAll(false);
      
	    this.useList=[]
	    this.currentPage = 1;
	    this.finished = false;
	    this.loading = true;
	    this.onLoad()

	},
      onRefresh() {
          // 清空列表数据
          this.finished = false;
          this.useList = []
          this.currentPage = 1
          // 重新加载数据
          // 将 loading 设置为 true，表示处于加载状态
          this.loading = true;
          this.refreshing = false
          this.onLoad();
          responseUtil.alertMsg(this,"刷新成功")
      },

        //审批操作保存接口
        save(){
	        // 审批拒绝接口
	        // 操作给出提示,重新回到列表页面
	        // 审批同意接口
	        let that = this
	        let initData={}
	        debugger
	        initData.payBillList = that.billId

	        initData.approvalOpinion = that.approvalOpinion
	        initData.approvalStatus = that.isPassValue   // 0 通过  1 拒绝
	        initData.staff_id = getStaffId()
          initData.modifiable=0  //不可改之前的付款账户和付款备注
            // if(1==1){
            //     return
            // }
            initData.sourceIdentification = 2  //手机批量支付
            approvalMergeBill(initData).then(function (response) {
	            responseUtil.dealResponse(that, response, () => {
                that.onRefresh();
                that.isPass = false;
                that.isReject = false;
                that.approvalOpinion = '';
                that.show = false;
                that.default();

                responseUtil.alertMsg(that,'保存成功')
	            })
	        })

	},
    payment() {
      // let billIdList = this.billId.toString()
      // let totalMoney = this.sum;
      // let billType = this.unique(this.billType).toString();
      // if (this.sum == 0) {
      //   Toast('请选择账单')
      // } else {
      //   if (openInWebview()) {
      //     if(checkAndroid()){
      //       goBill(billIdList,totalMoney,billType)
      //     }else if(checkIOS()){
      //       let billInfo = {
      //         billIdList: billIdList,
      //         billType: billType
      //       }
      //       window.webkit.messageHandlers.gotoPayBill.postMessage({body: billInfo});
      //     }
      //   }

      //}


	  if (this.result.length == 0) {
	    Toast('请选择账单')

	  } else {
      this.isPass = false;
      this.isReject = false;
      this.approvalOpinion = '';
      this.show = true;
	  }



    },

    //数组去重
    unique(arr){
      let tempTypeId = []
      for(let i=0,len=arr.length;i<len;i++){
        if(tempTypeId.indexOf(arr[i]) === -1){
          tempTypeId.push(arr[i]);
        }
      }
      return tempTypeId;
    }
  },

}
</script>

<style scoped>
/*body {*/
/*	padding-top: constant(safe-area-inset-top);*/
/*	padding-left: constant(safe-area-inset-left);*/
/*	padding-right: constant(safe-area-inset-right);*/
/*	!*padding-bottom: constant(safe-area-inset-bottom);*!*/
/*}*/
.navbar {
  background-color: white;
  border-bottom: 2px solid white;
}

.header {
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 8;
  top: 52px;
  background-color: #f8f8f8;
}

.choose {
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
}

.choose-one {
  width: 187px;
  text-align: center;
}

.choose-one-textActive {
  width: 42px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
  margin-left: 72px;
  color: rgba(255, 93, 59, 1);
  text-align: center;
  font-family: PingFangSC-Semibold;
}

.choose-one-text {
  width: 42px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-top: 24px;
  margin-left: 72px;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  font-family: PingFangSC-Semibold;
}

.choose-one-icon {
  width: 20px;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(255, 93, 59, 1);
  margin: 5px 0 15px 83px;
}

.choose-line {
  width: 2px;
  height: 17px;
  margin-top: 23px;
  background-color: rgba(239, 239, 239, 1);
}

.empty {
  margin-top: 250px;
  text-align: center;
}

.empty img {
  width: 85px;
}

.empryText {
  text-align: center;
  color: #ACACAC;
  color: rgba(172, 172, 172, 1);
  font-size: 12px;
  margin-top: 50px;
}

.totalBill {
  height: 25px;
  margin: 30px 15px 0 15px;
  font-weight: 600;
  font-size: 12px;
  color: #ff5d3b;
  margin-top: 55px;
}

.billInfo {
  width: 90%;
  height: 72px;
  border-left: 4px solid #ffa65c;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 15px;
  /*position:relative;*/
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkBox{
  	/*position: absolute;*/
	/*top: 35%;*/
  /*float:left;*/
}
::v-deep .van-checkbox {
  margin-top: 7px;
  float: left;
  margin-left: 10px;
}

.detail {
  font-size: 14px;
  width: 290px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 7px;
  margin-bottom: 5px;
}

.time {
  font-size: 12px;
}

.left {
  float: left;
  padding-left: 50px;
  padding-top:10px;
}

.right {
  float: right;
  position: absolute;
  margin-top: 30px;
  right: 35px;
}

.money {
  font-size: 15px;
  font-family: Oswald-Regular;
  /*margin-bottom: 5px;*/
  text-align: right;
}

.overdue {
  color: rgba(255, 93, 59, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: right;
}

.load {
  color: #ff5b39;
  text-align: center;
  font-size: 13px;
  margin-top: 9px;
  height: 100%;
  width: 100%;
  z-index: 0;
  position: fixed;
  overflow-x: hidden;
  margin-top: -90px;
}

.load img {
  width: 10px;
  height: 10px;
}

.payment {
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #ebebeb;
}

.btomcheck {
  font-size: 14px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.totalMoney {
  /*position: absolute;*/
  float: right;
  /*width: 50px;*/
  margin-left: 50%;
  margin-right: 30px;
  /*margin-top: 15px;*/
  /*top: 10px;*/
}

.bottomNavigationB {
  position: absolute;
  right: 10px;
  top: 10px;
  float: right;
  height: 40px;
  width: 86px;
  font-size: 14px;
  align-items: center;
  border-radius: 7px;
  background: linear-gradient(to right, #FFC274, #FF5D3B);
  color: white;
  /*margin-left: 70%;*/
  line-height: 40px;
  box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
  font-family: PingFangSC-Semibold;
  text-align: center;
}

.billList {
  margin-bottom: 130px;
}


.iphoneXBottom {
  /*position: fixed;*/
  /*left: 0;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  /*height: env(safe-area-inset-bottom);*/
  /*background: #fff;*/
  /*z-index: 500;*/
}
.search{
  margin: 8px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}
.search-icon{
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}
input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}
input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}
/* 审批图层 */
.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  line-height: 1rem;
  height: 50px;
  margin: 5px auto 0px;
}
.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}
.orangeColor {
  color: #ff3c00;
}
.blockTitle {
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}
.rightText {
  margin-left: auto;
  font-size: 15px;
  color: #999999;
}
.passText {
  flex: 1;
  padding-right: 7px;
}
.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}
.rejectText {
  padding-left: 7px;
  padding-right: 33px;
}
.displayBlock {
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  height: auto;
  margin: 10px auto;
}
.suggestTitle {
  margin-top: 6px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}
.suggestTitleOther {
  margin-top: 6px;
  margin-left: 32px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}
.textArea {
  resize: none;
  padding: 10px;
  margin: 39px auto 20px;
  height: 100px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: #fafafa;
}
.saveButton_Disable {
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 310px;
  height: 45px;
  border-radius: 8px;
  margin: 0px 0px 0px 33px;
  font-size: 18px;
  color: white;
  line-height: 45px;
  text-align: center;
}
.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll
    0% 0%;
}
.choosedStyle {
  color: #ff3c00;
  font-weight: bold;
}
/* 用于修复金额数值越界而写的新样式 */
.btomCheckNew {
  position: static;
  margin-top: 0;
}
.totalMoneyBox {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}
.bottomNavigationBNew {
  position: static;
  margin-right: 10px;
  margin-left: 10px;
}
.totalMoneyTitle {
  color: rgba(102, 102, 102, 1);
  font-size: 13px;
  margin-right: 5px;
}
.totalMoneyValue {
  color: rgba(255, 93, 59, 1);
  font-size: 18px;
  font-family: Oswald-Regular;
  font-weight: 500;
}
</style>
